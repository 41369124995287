import React, { useEffect, useState } from 'react';
import { Drawer, Menu } from 'antd';
import classes from './Sidebar.module.css';
import Banner from './assets/banner.png';
import SidebarContent from './SidebarContent/SidebarContent';

const MobileSidebar = ({ open, onClose, data, items, location }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleResizeWindow = () => {
      const windowSize = window.innerWidth;
      if (windowSize <= 1024) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    handleResizeWindow();
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  if (!visible) return null;

  return (
    <>
      <Drawer
        title={null}
        classNames={{ body: classes.drawer_body }}
        className={classes.drawer_mobile}
        placement={'left'}
        closable={false}
        onClose={onClose}
        open={open}
        key={'left'}
      >
        <SidebarContent data={data} items={items} location={location} />
      </Drawer>
    </>
  );
};
export default MobileSidebar;

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '../../hooks/useAxios';
import routes from '../../router/routess';
import { signOut } from '../../store/auth';
import classes from './Sidebar.module.css';
import MobileSidebar from './MobileSidebar';
import { useContext } from 'react';
import MainContext from '../MainLayout/MainContext';
import SidebarContent from './SidebarContent/SidebarContent';

const Sidebar = ({ location }) => {
  const { setCollapsed, collapsed } = useContext(MainContext);

  const { data, loading, refetch } = useLazyQuery('/business/settings');
  useEffect(() => {
    const refetchSettings = async () => {
      try {
        const response = await refetch();
        console.log(response, 'data1234');
      } catch (error) {
        console.error('Error fetching screens:', error);
      }
    };
    refetchSettings();
  }, [refetch]);
  const dispatch = useDispatch();
  const handleSignOut = () => {
    // #todo signOut fonksiyonunu buraya ekleyin
    dispatch(signOut());
  };
  const { screen_permissions } = useSelector((state) => state.auth);
  console.log(screen_permissions, 'screens');

  const menuItems = routes
    .filter(
      (route) => route.sidebar

      // #todo backende screenler eklenince burası yorumdan cıkacak
      // && screen_permissions?.find(e => e.screen_name === route.name + '_screen' && e.permissions.includes('preview'))
    )
    .map((route) => {
      return {
        key: route.path,
        icon: location.pathname === route.path && route.activeIcon ? route.activeIcon : route.icon || <UserOutlined />,
        label: <Link to={route.path}>{route.title}</Link>,
      };
    });

  const items = [
    ...menuItems,
    {
      type: 'divider',
      style: { margin: 0, border: 0, flexGrow: 1, visibility: 'hidden' },
    },
    // {
    //     key: '/logout', label: <Link onClick={
    //         handleSignOut
    //     }>
    //         Sign Out
    //     </Link>, icon: <UserOutlined />
    // }
  ];

  return (
    <>
      <Sider
        className={`${classes.root} ${collapsed ? classes.root_collapsed : ''}`}
        theme="light"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
        breakpoint="md"
        collapsed={collapsed}
        // onBreakpoint={(e) => setCollapsed(e)}
        trigger={
          <Button
            type="primary"
            icon={<LogoutOutlined />}
            style={{
              width: '100%',
              borderRadius: 0,
              marginTop: 'auto',
            }}
            // Sign out işlevini buraya ekleyin
            onClick={handleSignOut}
          >
            {!collapsed && 'Sign Out'}
          </Button>
        }
      >
        <SidebarContent data={data} items={items} location={location} />
      </Sider>
      <MobileSidebar
        open={collapsed}
        onClose={() => setCollapsed(false)}
        data={data}
        items={items}
        location={location}
      />
    </>
  );
};

export default Sidebar;

// authReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

const initialState = {
  token: localStorage.getItem('token'), // todo delete 'deneme token',
  refreshToken: localStorage.getItem('refreshToken'),
  isSigned: localStorage.getItem('token') ? true : false,
  screen_permissions: localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token'))?.access_screen : [],
  userId: localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token'))?.id : '',
  userData: localStorage.getItem('token')
    ? {
        firstname: jwtDecode(localStorage.getItem('token'))?.firstname,
        lastname: jwtDecode(localStorage.getItem('token'))?.lastname,
      }
    : null,
  isModalVisible: false,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      if (action.payload) {
        const decodedToken = jwtDecode(action.payload);
        state.isSigned = true;
        state.token = action.payload;
        localStorage.setItem('token', action.payload);
        state.screen_permissions = jwtDecode(action.payload)?.access_screen;
        state.userId = jwtDecode(action.payload)?.id;
        state.userData = {
          firstname: decodedToken?.firstname,
          lastname: decodedToken?.lastname,
        };
        // console.log(jwtDecode(action.payload));
        // console.log(state.userData);
        console.log('UserData');
        console.log(state.userData);
      } else {
        state.isSigned = false;
        state.userData = null;
        state.token = null;
        state.screen_permissions = [];
        localStorage.removeItem('token');
      }
    },
    setRefreshToken: (state, action) => {
      if (action.payload) {
        state.refreshToken = action.payload;
        localStorage.setItem('refreshToken', action.payload);
      } else {
        state.refreshToken = null;
        localStorage.removeItem('refreshToken');
      }
    },
    setScreenPermissions: (state, action) => {
      state.screen_permissions = action.payload;
    },
    signOut: (state) => {
      state.isSigned = false;
      state.token = null;
      state.screen_permissions = [];
      localStorage.removeItem('token');
      state.userId = null;
      state.userData = null;
    },
    signIn: (state) => {
      state.isSigned = true;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setModalVisible: (state, action) => {
      state.isModalVisible = action.payload;
    },
  },
});

export default auth.reducer;
export const { setToken, signOut, setRefreshToken, signIn, setUserId, setUserData, setModalVisible } = auth.actions;

import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import classes from '../Sidebar.module.css';
import Banner from '../assets/banner.png';
import { RiUser2Fill } from 'react-icons/ri';

const SidebarContent = ({ data, items, location }) => {
  const DemoCard = () => (
    <div className={classes.democard_container}>
      <div className={classes.democard_desc}>
        <span>Yeni</span>
        <span>Bordro</span>
        <span>Entegrasyonu</span>
        <button className={classes.democard_btn}>DEMOYU İNCELE</button>
      </div>
    </div>
  );

  return (
    <div style={{ height: '100vh', flex: 1, backgroundColor: '#755271' }}>
      {data?.data?.logo ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90px',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1000,
          }}
        >
          <Link to={'/'}>
            <img src={`https://node207217-hr-skript.de1.mageteknik.com/${data?.data?.logo}`} width="70px" />
          </Link>
        </div>
      ) : (
        <div
          style={{
            height: 90,
            margin: '0 16px',
            borderRadius: '4px',
            width: 'calc(100% - 32px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
          }}
        >
          Logo
        </div>
      )}

      <div
        style={{
          flex: 1,
          backgroundColor: '#755271',
        }}
      >
        <Menu
          className={classes.layout_sidebar}
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBlockEnd: '1rem',
          }}
          theme="light"
          mode="inline"
          defaultSelectedKeys={location.pathname}
          items={items?.slice(0, 8)}
        />
        {/* <DemoCard /> */}
        <img src={Banner} alt="Banner" className={classes.banner} />

        <Menu
          className={classes.layout_sidebar}
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBlockEnd: '1rem',
            paddingTop: 0,
          }}
          theme="light"
          mode="inline"
          defaultSelectedKeys={location.pathname}
          items={items?.slice(8)}
        />
      </div>
    </div>
  );
};

export default SidebarContent;

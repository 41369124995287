import { Layout, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import MainContext from './MainContext';
import classes from './MainLayout.module.css';

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <MainContext.Provider value={{ collapsed, setCollapsed }}>
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar location={location} />
        <Layout className={`${classes.side_layout} ${collapsed ? classes.site_layout_collapsed : ''}`}>
          <Content
            style={{
              padding: 20,
              paddingTop: 106,
            }}
          >
            {children}
          </Content>

          <Footer />
        </Layout>
      </Layout>
    </MainContext.Provider>
  );
};

export default MainLayout;
